import { Container } from "@material-ui/core";

import { GardenerInformationReviewTaskDocument } from "../../../task/task.model";
import { CommentDocument } from "../../../comment/comment.model";
import { Message } from "../../../Message";
import { ActivitySection } from "../../../comment/ActivitySection";
import { BackButton } from "../../../components/BackButton";
import { Section } from "../../../components/section";
import { TitleHeader } from "../../../components/TitleHeader";
import { ContentSection } from "../../../layout/ContentSection";
import { GardenerCommentList } from "../../../comment/GardenerCommentList";
import { ManualReviewTaskDetailCard } from "./ManualReviewTaskDetailCard";
import { GreenButton, VerticalButtons } from "../../../components/new-button";
import { ManualReviewResponseFormIds, useManualReviewResponseDialog } from "./ManualReviewResponseDialog";
import { GardenerDocument } from "../../../gardener/gardener.model";
import { IndividualSellerCompany } from "../../../company/company.model";
import { useCallback } from "react";
import { PersonalInformationDocument } from "../../../gardener/personal-information.model";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { FormItem } from "../../../components/form-item/framework/core/form-item.model";
import { SingleChoiceFormItem, TextAreaFormItem } from "../../../components/form-item/client/components";
import { AdministratorActionData } from "../../../workflow/administrator-action.hook";

export interface ManualReviewDetailPageProps {
    task: GardenerInformationReviewTaskDocument;
    gardener: GardenerDocument,
    company: IndividualSellerCompany | null;
    personalInformation: PersonalInformationDocument;
    administratorAction: (data: AdministratorActionData | string) => void;
    comments: CommentDocument[];
    addComment: (comment: string) => void;
    goBack: () => void;
}

export const ManualReviewDetailPage = (props: ManualReviewDetailPageProps) => {
    const { task, gardener, company, personalInformation, administratorAction, comments, addComment } = props;

    const backButton = <BackButton onClick={props.goBack} />;

    const responseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const rejectedName = (controller.getItem(ManualReviewResponseFormIds.SingleChoiceCheckName, item) as SingleChoiceFormItem<any> | null)?.selectedChoiceIndex === 0;
        if ( rejectedName ) {
            const reason = (controller.getItem(ManualReviewResponseFormIds.TextAreaRejectNameReason, item) as TextAreaFormItem).value.trim();

            return administratorAction({
                action: "reject gardener information",
                taskId: task.id,
                reason,
            });
        }

        const rejectedCpr = (controller.getItem(ManualReviewResponseFormIds.SingleChoiceCheckCpr, item) as SingleChoiceFormItem<any> | null)?.selectedChoiceIndex === 0;
        if ( rejectedCpr ) {
            const reason = (controller.getItem(ManualReviewResponseFormIds.TextAreaRejectCprReason, item) as TextAreaFormItem).value.trim();

            return administratorAction({
                action: "reject gardener information",
                taskId: task.id,
                reason,
            });
        }

        const rejectedParticipant = (controller.getItem(ManualReviewResponseFormIds.SingleChoiceCheckParticipant, item) as SingleChoiceFormItem<any> | null)?.selectedChoiceIndex === 0;
        if ( rejectedParticipant ) {
            const reason = "Dit navn stemmer ikke overens med virksomhedens ejer.";

            return administratorAction({
                action: "reject gardener information",
                taskId: task.id,
                reason,
            });
        }

        return administratorAction({
            action: "approve gardener information",
            taskId: task.id,
        });

    }, [administratorAction, task]);
    const openResponseDialog = useManualReviewResponseDialog(task, gardener, company, personalInformation, responseAdapter);

    return (
        <>
            <TitleHeader button={backButton}>Til gennemgang</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

                        <ManualReviewTaskDetailCard task={task} />

                        {!task.hasBeenPerformed ? (
                            <VerticalButtons>
                                <GreenButton onClick={openResponseDialog}>Besvar opgave</GreenButton>
                            </VerticalButtons>
                        ) : null}

                    </Section>

                    <ActivitySection onSave={addComment}>
                        <GardenerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
