import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { Dac7SactionRemovedCommentDocument } from "../comment.model";

interface Dac7SactionRemovedCommentProps {
    comment: Dac7SactionRemovedCommentDocument;
}

export const Dac7SactionRemovedComment = (props: Dac7SactionRemovedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="DAC7: Sanktion ophørt">
            <CommentBody>
                Havemandens honorar tilbageholdes ikke længere, da der er indsendt nye stamdata.
            </CommentBody>
        </Comment>
    );
};
