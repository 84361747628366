import { PageFormItem, useFormDialog, TextAreaFormItem, GroupFormItem } from "../components/form-item/client/components";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GardenerDocument } from "./gardener.model";

export enum UpdateContactInformationFormIds {
    TextAreaEmail = "TextAreaEmail",
    TextAreaPhone = "TextAreaPhone",
}

export type UpdateContactInformationForm =
    PageFormItem<
        GroupFormItem<[
            TextAreaFormItem,
            TextAreaFormItem,
        ]>
    >

export const useUpdateContactInformationDialog = (gardener: GardenerDocument, onConfirm: FormProps<UpdateContactInformationForm>["onSubmit"]) => {
    const form = primaryPage({
        email: emailTextArea(gardener.email),
        phone: phoneTextArea(gardener.phoneNumber),
    });

    return useFormDialog(form, onConfirm);
};

interface PrimaryPageProps {
    email: TextAreaFormItem;
    phone: TextAreaFormItem;
}

function primaryPage(props: PrimaryPageProps): UpdateContactInformationForm {
    return {
        type: "page",
        title: "Ret kontaktoplysninger",
        item: {
            type: "group",
            render: "all at once",
            items: [
                props.email,
                props.phone,
            ],
        },
    };
}

function emailTextArea(email: string): TextAreaFormItem {
    return {
        id: UpdateContactInformationFormIds.TextAreaEmail,
        type: "text-area",
        render: "text-field",
        label: "Email",
        placeholder: null,
        value: email,
        dirty: false,
        validation: "email",
    };
}

function phoneTextArea(phoneNumber: string): TextAreaFormItem {
    return {
        id: UpdateContactInformationFormIds.TextAreaPhone,
        type: "text-area",
        render: "text-field",
        label: "Telefon",
        placeholder: null,
        value: phoneNumber,
        dirty: false,
        validation: "phone",
    };
}
