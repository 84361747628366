import { useCallback, useMemo } from "react";

import { Container } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../../layout/ContentSection";
import { GardenerInformationReviewTaskDocument } from "../../task/task.model";
import { ListSection, ListSectionProps } from "../../components/admin-list-section/ListSection";
import { sortbyDueDateThenGardenerThenType } from "../../shared/utility";

type Task = GardenerInformationReviewTaskDocument;

interface ManualReviewTabProps {
    tasks: Task[];
    goToTask: (task: Task) => void;
}

export const ManualReviewTab = (props: ManualReviewTabProps) => {
    const { tasks, goToTask } = props;

    const currentTaskListItems: ListSectionProps["items"] = useMemo(() => {
        return tasks.sort(sortbyDueDateThenGardenerThenType).map(mapToListItem);
    }, [tasks]);

    const goToCurrentTask = useCallback((index: number) => {
        const task = tasks[index];

        goToTask(task);
    }, [goToTask, tasks]);

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters>

                <ListSection
                    title="Aktuelle opgaver"
                    placeholder="Der er ingen aktuelle opgaver"
                    items={currentTaskListItems}
                    onClick={goToCurrentTask}
                />

            </Container>
        </ContentSection>
    );
};

const mapToListItem = (task: Task): ListSectionProps["items"][0] => {
    const formattedDate = moment(task.performAfterDate).format("D MMMM YYYY HH:mm");

    return {
        heading: task.gardenerName,
        subheading: `Skal udføres ${formattedDate}`,
    };
}
