import { makeStyles } from "@material-ui/core";

import { Card } from "../components/card/Card";
import { CardContent } from "../components/card/CardContent";
import { GardenerApplicationDocument } from "./gardener-application.model";
import { CardHeader } from "../components/card/CardHeader";

import { GardenerDocument } from "../gardener/gardener.model";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },
    },
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        "& > a": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
    
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
    multiline: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        whiteSpace: "pre-wrap",
    },
});

interface ApplicationDetailCardProps {
    gardener: GardenerDocument;
    application: GardenerApplicationDocument;
}

export const ApplicationDetailCard = (props: ApplicationDetailCardProps) => {
    const classes = useStyles();

    const { gardener, application } = props;

    return (
        <Card>

            <CardHeader>{gardener.name}</CardHeader>

            <CardContent>

                <div className={classes.group}>
                    <label>Id</label>
                    <span className={classes.text}>{gardener.id}</span>
                </div>

                {gardener.cvr ? (
                    <div className={classes.group}>
                        <label>Virksomhed</label>
                        <span className={classes.text}>
                            <a href={`https://datacvr.virk.dk/enhed/virksomhed/${gardener.cvr}`} target="_blank" rel="noreferrer">
                                {gardener.companyName} (CVR: {gardener.cvr})
                            </a>
                        </span>
                    </div>
                ) : null}

                <div className={classes.group}>
                    <label>Erfaring med havearbejde</label>
                    <span className={classes.multiline}>{application.experience}</span>
                </div>

            </CardContent>

        </Card>
    );
};
