import { Container } from "@material-ui/core";
import CreateTaskIcon from "@material-ui/icons/AddAlertOutlined";
import LoginIcon from "@material-ui/icons/AccountCircle";
import PauseIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import UnpauseIcon from "@material-ui/icons/PlayCircleOutline";
import RejectIcon from "@material-ui/icons/CancelOutlined";

import { ContentSection } from "../layout/ContentSection";
import { TitleHeader } from "../components/TitleHeader";
import { LinearProgress } from "../components/LinearProgress";
import { NotFoundPage } from "../NotFoundPage";
import { BackButton } from "../components/BackButton";
import { GardenerCommentList } from "../comment/GardenerCommentList";
import { useGardenerComments } from "../comment/comments.hook";
import { GardenerDetailCard } from "./GardenerDetailCard";
import { useAddGardenerComment } from "../comment/add-comment.hook";
import { ActivitySection } from "../comment/ActivitySection";
import { MenuButton } from "../components/menu/MenuButton";
import { MenuItem } from "../components/menu/MenuItem";
import { useLogInAsGardener } from "./log-in-as-gardener.hook";
import { useTerminateGardenerAction } from "./terminate-gardener-action.hook";
import { MenuDivider } from "../components/menu/MenuDivider";
import { useManualAdministratorProtection } from "../protection/protection.hook";
import { usePauseGardener } from "./pause-gardener/pause-gardener.hook";
import { useUnpauseGardener } from "./unpause-gardener/unpause-gardener.hook";
import { useCreateGardenerTask } from "./task-dialog/create-gardener-task.hook";
import { useCallback, useMemo } from "react";
import { ManageAccountsIcon } from "../components/icon/ManageAccountsIcon";
import { TerminateGardenerDialogViewModel, useTerminateGardenerDialog } from "./TerminateGardenerDialog";
import { GardenerDocument } from "./gardener.model";
import { useGardenerApplication } from "../gardener-application/gardener-applications.hook";
import { Section, SectionHeader } from "../components/section";
import { GardeningTaskDocument } from "../gardening-task/gardening-task.model";
import { CustomerDocument } from "../customer/customer.model";
import { GardeningTaskGardenerList } from "../gardening-task/list/GardeningTaskGardenerList";
import { GuardrailsProtectionDocument } from "../evaluate-gardener/shared/guardrails-protection.model";
import { UpdateContactInformationFormIds, useUpdateContactInformationDialog } from "./UpdateContactInformationDialog";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { FormItem } from "../components/form-item/framework/core/form-item.model";
import { AdministratorActionData } from "../workflow/administrator-action.hook";
import { TextAreaFormItem } from "../components/form-item/client/components";

interface GardenerPageProps {
    gardener: GardenerDocument;
    protection: GuardrailsProtectionDocument;
    gardeningTasks: GardeningTaskDocument[];
    customers: CustomerDocument[];
    goBack: () => void;
    onSelectGardeningTask: (gardeningTask: GardeningTaskDocument) => void;
    administratorAction: (data: AdministratorActionData | string) => void;
}

export const GardenerPage = (props: GardenerPageProps) => {
    const { gardener, protection, gardeningTasks, customers, onSelectGardeningTask, administratorAction } = props;

    const application = useGardenerApplication(gardener.id);
    const manualAdministratorProtection = useManualAdministratorProtection(gardener.id);
    const comments = useGardenerComments(gardener.id);

    const addComment = useAddGardenerComment(gardener.id);

    const logInAsGardener = useLogInAsGardener(gardener);
    const pauseGardener = usePauseGardener(gardener.id);
    const unpauseGardener = useUnpauseGardener(gardener.id);
    const createGardenerTask = useCreateGardenerTask(gardener);

    const initialTerminateGardenerDialogViewModel = useMemo<TerminateGardenerDialogViewModel | undefined>(() => {
        return {
            gardenerName: gardener.name,
            reason: "",
            reasonDirty: false,
            explanation: "",
            explanationDirty: false,
            confirmation: "",
            confirmationDirty: false,
        };
    }, [gardener]);
    const terminateGardener = useTerminateGardenerAction(gardener.id);
    const openTerminateGardenerDialog = useTerminateGardenerDialog(terminateGardener, initialTerminateGardenerDialogViewModel);

    const updateContactInformationAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, form) => {
        const email = (controller.getItem(UpdateContactInformationFormIds.TextAreaEmail, form) as TextAreaFormItem).value;
        const phoneNumber = (controller.getItem(UpdateContactInformationFormIds.TextAreaPhone, form) as TextAreaFormItem).value;

        return administratorAction({
            action: "update gardener contact information",
            gardenerId: gardener.id,
            email,
            phoneNumber,
        });
    }, [administratorAction, gardener]);
    const openUpdateContactInformationDialog = useUpdateContactInformationDialog(gardener, updateContactInformationAdapter);
    
    if ( manualAdministratorProtection === null ) return <NotFoundPage />;
    if ( application === undefined || manualAdministratorProtection === undefined || comments === undefined ) return <LinearProgress />;

    const backButton = <BackButton onClick={props.goBack} />;

    const toggleManualAdministratorProtectionButton = !manualAdministratorProtection.enabled ? (
        <MenuItem icon={<PauseIcon />} onClick={pauseGardener}>Sæt på pause</MenuItem>
    ) : (
        <MenuItem icon={<UnpauseIcon />} onClick={unpauseGardener}>Genaktiver</MenuItem>
    );

    const menuButton = (() => {

        if ( gardener.status === "terminated" ) {
            return (
                <MenuButton>
                    <MenuItem icon={<LoginIcon />} onClick={logInAsGardener}>Log ind som</MenuItem>
                </MenuButton>
            );
        }

        if ( gardener.status === "active" ) {
            return (
                <MenuButton>
                    <MenuItem icon={<LoginIcon />} onClick={logInAsGardener}>Log ind som</MenuItem>
                    {toggleManualAdministratorProtectionButton}
                    <MenuItem icon={<CreateTaskIcon />} onClick={createGardenerTask}>Planlæg diverse</MenuItem>
                    <MenuItem icon={<ManageAccountsIcon />} onClick={openUpdateContactInformationDialog}>Ret kontaktoplysninger</MenuItem>
                    <MenuDivider />
                    <MenuItem icon={<RejectIcon />} onClick={openTerminateGardenerDialog}>Ophør samarbejde</MenuItem>
                </MenuButton>
            );
        }

        return undefined;
    })();

    return (
        <>
            <TitleHeader button={backButton}>{gardener.name}</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        <GardenerDetailCard gardener={gardener} application={application} button={menuButton} />
                    </Section>

                    <Section>
                        <SectionHeader>Igangværende opgaver ({protection.currentProvidedGardeningTaskIds.length}/{Math.floor(protection.score)})</SectionHeader>
                        <GardeningTaskGardenerList gardeningTasks={gardeningTasks} customers={customers} onSelect={onSelectGardeningTask} />
                    </Section>

                    <ActivitySection onSave={addComment}>
                        <GardenerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
