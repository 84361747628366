import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { TaskDocument } from "../../task/task.model";
import { useGardenerInformationReviewTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { ManualReviewTab } from "./ManualReviewTab";

export const IntegratedManualReviewTab = () => {
    const history = useHistory();
    const goToTask = useCallback((task: TaskDocument) => {
        history.push(`/check-gardener-information/manual-review/${task.id}`);
    }, [history]);

    const tasks = useGardenerInformationReviewTasks();

    return (
        <ManualReviewTab
            tasks={tasks}
            goToTask={goToTask}
        />
    );
};
