import { Container } from "@material-ui/core";
import moment from "moment";

import { OnboardingTaskDocument } from "../../../task/task.model";
import { CommentDocument } from "../../../comment/comment.model";
import { Message } from "../../../Message";
import { ActivitySection } from "../../../comment/ActivitySection";
import { BackButton } from "../../../components/BackButton";
import { Section } from "../../../components/section";
import { TitleHeader } from "../../../components/TitleHeader";
import { ContentSection } from "../../../layout/ContentSection";
import { AdministratorActionData } from "../../../workflow/administrator-action.hook";
import { useCallback } from "react";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { FormItem } from "../../../components/form-item/framework/core/form-item.model";
import { GardenerCommentList } from "../../../comment/GardenerCommentList";
import { InterviewTaskDetailCard } from "./InterviewTaskDetailCard";
import { GardenerDocument } from "../../../gardener/gardener.model";
import { GreenButton, VerticalButtons } from "../../../components/new-button";
import { InterviewResponseFormIds, useInterviewResponseDialog } from "./InterviewResponseDialog";
import { SingleChoiceFormItem, TextAreaFormItem } from "../../../components/form-item/client/components";
import { ApplicationDetailCard } from "../../../gardener-application/ApplicationDetailCard";
import { SupportedCompany } from "../../../company/company.model";

export interface InterviewDetailPageProps {
    gardener: GardenerDocument;
    company: SupportedCompany | null;
    task: OnboardingTaskDocument;
    administratorAction: (data: AdministratorActionData | string) => void;
    comments: CommentDocument[];
    addComment: (comment: string) => void;
    goBack: () => void;
    now: string;
}

export const InterviewDetailPage = (props: InterviewDetailPageProps) => {
    const { gardener, company, task, administratorAction, comments, addComment, now } = props;

    const backButton = <BackButton onClick={props.goBack} />;

    const responseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const reachedApplicant = (controller.getItem(InterviewResponseFormIds.SingleChoiceReachedApplicant, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !reachedApplicant ) {
            return administratorAction({
                action: "register did not reach applicant",
                taskId: task.id,
            });
        }

        const stillInterested = (controller.getItem(InterviewResponseFormIds.SingleChoiceStillInterested, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !stillInterested ) {
            return administratorAction({
                action: "reject application",
                taskId: task.id,
                reason: "applicant retracted application",
            });
        }

        const canTalkNow = (controller.getItem(InterviewResponseFormIds.SingleChoiceCanTalkNow, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !canTalkNow ) {
            const tomorrowAtEight = moment(now).add(1, "day").startOf("day").add(8, "hours").toISOString(true);

            return administratorAction({
                action: "reschedule call about application",
                taskId: task.id,
                newDate: tomorrowAtEight,
                reason: "Ansøgeren har ikke tid til at snakke lige nu",
            });
        }

        const fluentInDanish = (controller.getItem(InterviewResponseFormIds.SingleChoiceFluentInDanish, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !fluentInDanish ) {
            return administratorAction({
                action: "reject application",
                taskId: task.id,
                reason: "applicant is not fluent in danish",
            });
        }

        const willOnboardApplicant = (controller.getItem(InterviewResponseFormIds.SingleChoiceWillOnboardApplicant, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !willOnboardApplicant ) {
            return administratorAction({
                action: "reject application",
                taskId: task.id,
                reason: "applicant is a bad fit",
            });
        }

        const name = (controller.getItem(InterviewResponseFormIds.TextAreaName, item) as TextAreaFormItem).value.trim();

        if ( company && company.type !== "personligt ejet mindre virksomhed" && company.type !== "enkeltmandsvirksomhed" ) {
            return administratorAction({
                action: "approve application",
                taskId: task.id,
                name,
                cpr: null,
            });
        }

        const willGiveCpr = (controller.getItem(InterviewResponseFormIds.SingleChoiceWillGiveCpr, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !willGiveCpr ) {
            return administratorAction({
                action: "reject application",
                taskId: task.id,
                reason: "applicant would not hand over cpr",
            });
        }

        const cpr = (controller.getItem(InterviewResponseFormIds.TextAreaCpr, item) as TextAreaFormItem).value;

        return administratorAction({
            action: "approve application",
            taskId: task.id,
            name,
            cpr,
        });

    }, [administratorAction, company, task, now]);
    const openResponseDialog = useInterviewResponseDialog(gardener, company, task, responseAdapter);

    return (
        <>
            <TitleHeader button={backButton}>Samtale</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>

                        {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

                        <InterviewTaskDetailCard task={task} administratorAction={administratorAction} />
                        <ApplicationDetailCard gardener={gardener} application={task.application} />

                        {!task.hasBeenPerformed ? (
                            <VerticalButtons>
                                <GreenButton onClick={openResponseDialog}>Besvar opgave</GreenButton>
                            </VerticalButtons>
                        ) : null}

                    </Section>

                    <ActivitySection onSave={addComment}>
                        <GardenerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
