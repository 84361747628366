import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, HorizontalButtonsSingleChoiceFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";
import { SupportedCompany } from "../../company/company.model";

export interface GetCprFormIds {
    SingleChoiceWillGiveCpr: string;
    TextAreaCpr: string;
}

export type GetCprForm<NextPage extends FormItem> = GetCprPage<NextPage> | NextPage;

type GetCprPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<Array<InfoFormItem | NoticeFormItem | SingleChoice<NextPage>>>
    >;

type SingleChoice<NextPage extends FormItem> =
    HorizontalButtonsSingleChoiceFormItem<[
        ConfirmationPage,
        GroupFormItem<[
            NoticeFormItem,
            TextAreaFormItem,
            NextPage,
        ]>,
    ]>;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useGetCprForm = <T extends FormItem>(company: SupportedCompany | null, nextPage: T, formIds: GetCprFormIds): GetCprForm<T> => {
    return useMemo(() => {
        if ( company && company.type !== "personligt ejet mindre virksomhed" && company.type !== "enkeltmandsvirksomhed" ) return nextPage;

        const willNotGiveCprConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist fordi de ikke ville udlevere sit CPR-nummer, som vi har brug for jf. DAC7.
                `,
            },
        };

        const items: Array<InfoFormItem | NoticeFormItem | SingleChoice<T>> = [];

        items.push({
            type: "info",
            render: "default",
            text: `Ansøgeren har ansøgt som ${company?.type ?? "privatperson"}. Derfor har vi brug for deres CPR-nummer.`,
        });

        items.push({
            type: "notice",
            render: "info",
            html: `
                <strong>Om DAC7-lovgivning</strong><br />
                <br />
                Da vi er en digital platform, er vi underlagt lovgivning, som betyder, at vi skal opkræve dit CPR-nummer.
            `,
        });

        if ( !company ) {
            items.push({
                type: "notice",
                render: "info",
                html: `
                    <strong>Om CPR-nummer på fakturaer</strong><br />
                    <br />
                    Da du ikke har et CVR-nummer, vil dit CPR-nummer blive påført dine fakturaer, fordi det er et krav ift. bogføringsloven, og dine kunder har brug for det, for at kunne opnå servicefradrag.
                `,
            });
        }

        items.push({
            id: formIds.SingleChoiceWillGiveCpr,
            type: "single-choice",
            mode: "switch page",
            switchPageIndexes: [0],
            render: "horizontal-buttons",
            questionText: "Vil ansøgeren udlevere sit CPR-nummer?",
            answerTexts: ["Nej", "Ja"],
            answerItems: [
                willNotGiveCprConfirmationPage,
                {
                    type: "group",
                    render: "all at once",
                    items: [
                        {
                            type: "notice",
                            render: "warning",
                            html: "Tjek at CPR-nummeret passer og at kønnet også passer.",
                        },
                        {
                            id: formIds.TextAreaCpr,
                            type: "text-area",
                            render: "text-field",
                            label: "CPR-nummer",
                            placeholder: null,
                            value: "",
                            dirty: true,
                            validation: "cpr",
                        },
                        nextPage,
                    ],
                },
            ],
            selectedChoiceIndex: null,
            dirty: false,
        });

        const page: GetCprPage<T> = {
            type: "page",
            title: "Bed ansøgeren om CPR-nummer",
            item: {
                type: "group",
                render: "all at once",
                items: items,
            },
        };
        
        return page;
    }, [company, nextPage, formIds]);
}
