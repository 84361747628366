import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { Dac7SactionAddedCommentDocument } from "../comment.model";

interface Dac7SactionAddedCommentProps {
    comment: Dac7SactionAddedCommentDocument;
}

export const Dac7SactionAddedComment = (props: Dac7SactionAddedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="DAC7: Sanktion indført">
            <CommentBody>
                Havemanden har ikke reageret på vores anmodning og to påmindelser om indsendelse af stamdata.<br />
                <br />
                Vi er forpligtede til at sanktionere havemanden jf. DAC7 og derfor tilbageholder vi nu havemandens honorar indtil han indsender stamdata.
            </CommentBody>
        </Comment>
    );
};
