import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../components/blocking-progress";
import { useSnackbar } from "../components/snackbar";
import { Skill, TaskFrequency, HourInterval, TaskTools } from "../gardening-task/gardening-task.model";

export type AdministratorActionData =
    | CreateCustomerData
    | DeleteCustomerData
    | CreateGardeningTaskData
    | UpdateGardeningTaskData
    | AbortGardeningTaskData
    | GiveTaskToGardenerData
    | RejectApplicationData
    | ScheduleCallAboutApplicationData
    | RescheduleCallAboutApplicationData
    | RegisterDidNotReachApplicantData
    | ApproveApplicationData
    | RegisterDidNotReachCustomerForCallBeforeInvoiceReminderFee1Data
    | RegisterDidReachCustomerForCallBeforeInvoiceReminderFee1Data
    | RegisterDidNotReachCustomerForCallBeforeDebtCollectionData
    | RegisterDidReachCustomerForCallBeforeDebtCollectionData
    | CreateComplaintData
    | RegisterDidNotReachCustomerForComplaintData
    | RegisterCustomerDidNotHaveProposalForComplaintData
    | RegisterCustomerDidHaveProposalForComplaintData
    | RegisterDidNotReachGardenerForComplaintData
    | RegisterDidReachGardenerForComplaintData
    | HandOverComplaintToGardenerData
    | OfferProposalForComplaintData
    | CloseComplaintData
    | RegisterPartnershipContactAttemptData
    | IssueWarningToGardenerData
    | TerminateGardenerData
    | UpdateGardenerContactInformationData
    | RejectGardenerInformationData
    | ApproveGardenerInformationData

export interface CreateCustomerData {
    action: "create customer",
    name: string;
    email: string;
    phoneNumber: string;
    externalAddressId: string;
}

export type DeleteCustomerReason = "customer-asked-to-be-deleted" | "order-is-irrelevant";
export interface DeleteCustomerData {
    action: "delete customer",
    customerId: string;
    reason: DeleteCustomerReason;
    explanation: string;
}

export interface CreateGardeningTaskData {
    action: "create gardening task",
    customerId: string;
    externalAddressId: string;
    requiredSkills: Skill[];
    frequency: TaskFrequency;
    hourInterval: HourInterval;
    tools: TaskTools;
    description: string;
    findGardenerStrategy: {
        type: "look for gardener",
    } | {
        type: "specific gardener",
        gardenerId: string,
        reason: string;
    },
}

export interface UpdateGardeningTaskData {
    action: "update gardening task",
    gardeningTaskId: string;
    externalAddressId: string;
    requiredSkills: Skill[];
    frequency: TaskFrequency;
    hourInterval: HourInterval;
    tools: TaskTools;
    description: string;
}

export interface AbortGardeningTaskData {
    action: "abort gardening task",
    gardeningTaskId: string;
    reason: string;
}

export interface GiveTaskToGardenerData {
    action: "give task to gardener",
    gardeningTaskId: string;
    gardenerId: string,
    reason: string;
}
    
export type RejectApplicationReason =
    | "application has not been completed"
    | "application not in danish"
    | "applicant experience not evident"
    | "applicant does not sign for specified company"
    | "applicant could not be reached"
    | "applicant retracted application"
    | "applicant is not fluent in danish"
    | "applicant is a bad fit"
    | "applicant would not hand over cpr"

export interface RejectApplicationData {
    action: "reject application",
    taskId: string,
    reason: RejectApplicationReason,
}

export interface ScheduleCallAboutApplicationData {
    action: "schedule call about application",
    taskId: string,
}

export interface RescheduleCallAboutApplicationData {
    action: "reschedule call about application",
    taskId: string,
    newDate: string,
    reason: string,
}

export interface RegisterDidNotReachApplicantData {
    action: "register did not reach applicant",
    taskId: string,
}

export type ApproveApplicationData = {
    action: "approve application";
    taskId: string;
    name: string;
    cpr: string | null;
}

export interface RegisterDidNotReachCustomerForCallBeforeInvoiceReminderFee1Data {
    action: "register did not reach customer for call before invoice reminder fee 1";
    taskId: string;
}

export interface RegisterDidReachCustomerForCallBeforeInvoiceReminderFee1Data {
    action: "register did reach customer for call before invoice reminder fee 1";
    taskId: string;
    summary: string;
}

export interface RegisterDidNotReachCustomerForCallBeforeDebtCollectionData {
    action: "register did not reach customer for call before debt collection";
    taskId: string;
}

export interface RegisterDidReachCustomerForCallBeforeDebtCollectionData {
    action: "register did reach customer for call before debt collection";
    taskId: string;
    summary: string;
}

export interface CreateComplaintData {
    action: "create complaint";
    invoiceId: string;
}

export interface RegisterDidNotReachCustomerForComplaintData {
    action: "register did not reach customer for complaint";
    taskId: string;
}

export interface RegisterCustomerDidNotHaveProposalForComplaintData {
    action: "register customer did not have proposal for complaint";
    taskId: string;
    customerComment: string;
}

export interface RegisterCustomerDidHaveProposalForComplaintData {
    action: "register customer did have proposal for complaint";
    taskId: string;
    customerComment: string;
    customerProposal: string;
}

export interface RegisterDidNotReachGardenerForComplaintData {
    action: "register did not reach gardener for complaint";
    taskId: string;
}

export interface RegisterDidReachGardenerForComplaintData {
    action: "register did reach gardener for complaint";
    taskId: string;
    gardenerComment: string;
    gardenerProposal: string;
}

export interface HandOverComplaintToGardenerData {
    action: "hand over complaint to gardener";
    taskId: string;
    reason: string;
}

export interface OfferProposalForComplaintData {
    action: "offer proposal for complaint";
    taskId: string;
    platformComment: string;
    platformProposal: string;
}

export interface CloseComplaintData {
    action: "close complaint";
    complaintId: string;
    reason: string;
}

export interface RegisterPartnershipContactAttemptData {
    action: "register partnership contact attempt";
    taskId: string;
}

export interface IssueWarningToGardenerData {
    action: "issue warning to gardener";
    taskId: string;
}

export interface TerminateGardenerData {
    action: "terminate gardener";
    taskId: string;
}

export interface UpdateGardenerContactInformationData {
    action: "update gardener contact information",
    gardenerId: string;
    email: string;
    phoneNumber: string;
}

export interface RejectGardenerInformationData {
    action: "reject gardener information",
    taskId: string;
    reason: string;
}

export interface ApproveGardenerInformationData {
    action: "approve gardener information",
    taskId: string;
}

const administratorAction: (data: AdministratorActionData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Workflow-administratorAction");

export const useAdministratorAction = (callback?: (action: AdministratorActionData["action"], result: any) => void) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    return useCallback((data: AdministratorActionData | string) => {
        if ( typeof data === "string" ) return;

        block();

        administratorAction(data)
            .then((result) => {
                if ( result.data === "cannot delete because of open important tasks" ) {
                    openSnackbar("warning", "Kunden kunne ikke slettes pga. udestående opgaver inden for opkrævning eller reklamation");
                    unblock();
                    return;
                }

                if ( result.data === "already looking for gardener" ) {
                    openSnackbar("warning", "Opgaven kunne ikke oprettes, da vi allerede leder efter en havemand til en anden af kundens opgaver",);
                    unblock();
                    return;
                }

                if ( result.data === "gardening-task-has-not-been-changed" ) {
                    openSnackbar("warning", "Der var ikke angivet nogen ændringer til opgaven");
                    unblock();
                    return;
                }

                openSnackbar("success", "Din handling er registreret");
                unblock();
                if ( callback ) callback(data.action, result.data);
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Din handling er ikke blevet registreret.");
                unblock();
            });

    }, [block, unblock, openSnackbar, callback]);
};
