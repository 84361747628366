import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { GardenerInformationInvalidatedCommentDocument } from "../comment.model";

interface GardenerInformationInvalidatedCommentProps {
    comment: GardenerInformationInvalidatedCommentDocument;
}

export const GardenerInformationInvalidatedComment = (props: GardenerInformationInvalidatedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="DAC7: Ugyldige stamdata" author={comment.administratorName ?? undefined}>
            <CommentBody>
                Havemandens stamdata er blevet registreret som ugyldige. Havemanden er derfor nødt til at indsende nye stamdata.<br />
                <br />
                Årsag:<br />
                “{comment.reason}”
            </CommentBody>
        </Comment>
    );
};
