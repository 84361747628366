import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { GardenerInformationRejectedCommentDocument } from "../comment.model";

interface GardenerInformationRejectedCommentProps {
    comment: GardenerInformationRejectedCommentDocument;
}

export const GardenerInformationRejectedComment = (props: GardenerInformationRejectedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Stamdata afvist" author={comment.administratorName ?? undefined}>
            <CommentBody>
                Havemandens nye stamdata blev afvist ved {comment.administratorName ? "manuel gennemgang" : "automatisk gennemgang"}.<br />
                <br />
                Årsag:<br />
                “{comment.reason}”
            </CommentBody>
        </Comment>
    );
};
