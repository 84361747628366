import { useAddGardenerComment } from "../../../comment/add-comment.hook";
import { useGardenerComments } from "../../../comment/comments.hook";
import { GardenerInformationDueDiligenceTaskDocument } from "../../../task/task.model";
import { LinearProgress } from "../../../components/LinearProgress";
import { AutomaticDetailPage } from "./AutomaticDetailPage";

interface IntegratedAutomaticDetailPageProps {
    task: GardenerInformationDueDiligenceTaskDocument;
    goBack: () => void;
}

export const IntegratedAutomaticDetailPage = (props: IntegratedAutomaticDetailPageProps) => {
    const { task, goBack } = props;

    const comments = useGardenerComments(task?.gardenerId);
    const addComment = useAddGardenerComment(task?.gardenerId);

    if ( comments === undefined ) return <LinearProgress />;

    return (
        <AutomaticDetailPage
            task={task}
            comments={comments}
            addComment={addComment}
            goBack={goBack}
        />
    );
};
