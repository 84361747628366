import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card } from "../../../components/card/Card";
import { CardHeader } from "../../../components/card/CardHeader";
import { CardContent } from "../../../components/card";
import { GardenerInformationReviewTaskDocument } from "../../../task/task.model";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            lineHeight: "125%",
            whiteSpace: "pre-wrap",
        },
    },
    order: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
});

interface ManualReviewTaskDetailCardProps {
    task: GardenerInformationReviewTaskDocument;
}

export const ManualReviewTaskDetailCard = (props: ManualReviewTaskDetailCardProps) => {
    const classes = useStyles();

    const { task } = props;

    return (
        <Card>
            <CardHeader>{task.gardenerName}</CardHeader>

            <CardContent className={classes.order}>

                <div className={classes.group}>
                    <label>Hvad er der hændt?</label>
                    <span>Havemanden har ændret sine stamdata.</span>
                </div>

                <div className={classes.group}>
                    <label>Hvad skal der gøres?</label>
                    <span>Tag stilling til om de nye stamdata kan godkendes.</span>
                </div>

                <div className={classes.group}>
                    <label>Hvornår skal det udføres?</label>
                    <span>{moment(task.performAfterDate).format("D MMMM YYYY HH:mm")}</span>
                </div>

            </CardContent>

        </Card>
    );
};
