import danishSsn from "danish-ssn";

import { GroupFormItem, InfoFormItem, NoticeFormItem, PageFormItem, SingleChoiceFormItem, TextAreaFormItem, useFormDialog } from "../../../components/form-item/client/components";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { GardenerInformationReviewTaskDocument } from "../../../task/task.model";
import { GardenerDocument } from "../../../gardener/gardener.model";
import { IndividualSellerCompany } from "../../../company/company.model";
import { PersonalInformationDocument } from "../../../gardener/personal-information.model";
import { CheckParticipantPage as CheckParticipantPageImpl, checkParticipantPage as checkParticipantPageImpl } from "../../../onboarding/shared/CheckCompanySigning";
import { FormItem } from "../../../components/form-item/framework/core/form-item.model";
import { useMemo } from "react";

export enum ManualReviewResponseFormIds {
    SingleChoiceCheckName = "SingleChoiceCheckName",
    TextAreaRejectNameReason = "TextAreaRejectNameReason",
    SingleChoiceCheckCpr = "SingleChoiceCheckCpr",
    TextAreaRejectCprReason = "TextAreaRejectCprReason",
    SingleChoiceCheckParticipant = "SingleChoiceCheckParticipant",
}

export type ManualReviewResponseForm =
    CheckNamePage<
        ConfirmationPage,
        CheckCprPage<
            ConfirmationPage,
            CheckParticipantPage<
                ConfirmationPage,
                ConfirmationPage
            >
        >
    >

type CheckNamePage<RejectPage extends FormItem, ApprovePage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NoticeFormItem,
            SingleChoiceFormItem<[
                GroupFormItem<[
                    TextAreaFormItem,
                    RejectPage,
                ]>,
                ApprovePage,
            ]>
        ]>
    > | ApprovePage;

type CheckCprPage<RejectPage extends FormItem, ApprovePage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NoticeFormItem,
            SingleChoiceFormItem<[
                GroupFormItem<[
                    TextAreaFormItem,
                    RejectPage,
                ]>,
                ApprovePage,
            ]>
        ]>
    > | ApprovePage;

type CheckParticipantPage<RejectPage extends FormItem, ApprovePage extends FormItem> = CheckParticipantPageImpl<RejectPage, ApprovePage> | ApprovePage;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useManualReviewResponseDialog = (task: GardenerInformationReviewTaskDocument, gardener: GardenerDocument, company: IndividualSellerCompany | null, personalInformation: PersonalInformationDocument, onConfirm: FormProps<ManualReviewResponseForm>["onSubmit"]) => {
    const form = useMemo(() => {
        return manualReviewResponseForm(task, gardener, company, personalInformation);
    }, [task, gardener, company, personalInformation]);

    return useFormDialog(form, onConfirm);
};

function manualReviewResponseForm(task: GardenerInformationReviewTaskDocument, gardener: GardenerDocument, company: IndividualSellerCompany | null, personalInformation: PersonalInformationDocument): ManualReviewResponseForm {
    const rejectPage = rejectChangePage();
    const approvePage = approveChangePage();
    
    const checkCompany = checkParticipantPage(task, gardener, company, rejectPage, approvePage);
    
    const checkCpr = checkCprPage(task, personalInformation, rejectPage, checkCompany);
    const checkName = checkNamePage(task, gardener, rejectPage, checkCpr);

    return checkName;
}

function checkNamePage<RejectPage extends FormItem, ApprovePage extends FormItem>(task: GardenerInformationReviewTaskDocument, gardener: GardenerDocument, rejectPage: RejectPage, approvePage: ApprovePage): CheckNamePage<RejectPage, ApprovePage> {
    const shouldCheckName = gardener.name.toLowerCase() !== task.data.name.toLowerCase();
    if ( !shouldCheckName ) return approvePage;

    if ( !task.reasonForUpdatingName ) throw new Error("Expected reason for updating name");

    return {
        type: "page",
        title: "Tjek navn",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: "Du skal tjekke om havemandens ændring opfylder vores regler.",
                },
                {
                    type: "notice",
                    render: "info",
                    html: `
                        <strong>Havemandens tidligere navn</strong><br />
                        ${gardener.name}<br />
                        <br />
                        <strong>Havemandens nye navn</strong><br />
                        ${task.data.name}<br />
                        <br />
                        <strong>Årsag til ændring af navn</strong><br />
                        ${task.reasonForUpdatingName}
                    `,
                },
                {
                    type: "notice",
                    render: "success",
                    html: `
                        <strong>Tilladte ændringer</strong><br />
                        <br />
                        <ul>
                            <li>Havemand må gerne præcisere navnet, f.eks. ved at tilføje manglende mellemnavne</li>
                            <li>Hvis navnet var forkert angivet før, må havemanden gerne rette navnet</li>
                            <li>Hvis havemanden har skiftet juridisk navn, f.eks. ved skilsmisse/giftemål, må havemanden gerne rette navnet</li>
                        </ul>
                    `,
                },
                {
                    type: "notice",
                    render: "danger",
                    html: `
                        <strong>Skal overholdes</strong><br />
                        <br />
                        <ul>
                            <li>Navnet skal være et ægte personnavn</li>
                            <li>Navnet skal stå i den rigtige rækkefølge, dvs. fornavn efterfulgt af efternavn</li>
                            <li>Navnet må ikke indeholde forkortelser, punktummer eller andre specialtegn</li>
                            <li>Profilen må ikke overdrages til en anden person</li>
                        </ul>
                    `,
                },
                {
                    type: "notice",
                    render: "warning",
                    html: `
                        <strong>OBS:</strong> Kan du ikke afgøre ud fra havemandens årsag, om det er en gyldig rettelse, så skal du afvise den.
                    `,
                },
                {
                    id: ManualReviewResponseFormIds.SingleChoiceCheckName,
                    type: "single-choice",
                    mode: "regular",
                    render: "horizontal-buttons",
                    questionText: "Vil du godkende havemandens ændring?",
                    answerTexts: ["Nej", "Ja"],
                    answerItems: [
                        {
                            type: "group",
                            render: "all at once",
                            items: [
                                {
                                    id: ManualReviewResponseFormIds.TextAreaRejectNameReason,
                                    type: "text-area",
                                    label: "Årsag til afvisning af stamdata (sendes til havemanden)",
                                    placeholder: "Fortæl havemanden hvorfor ændringen ikke kan godkendes",
                                    value: "",
                                    dirty: false,
                                    validation: "mandatory",
                                },
                                rejectPage,
                            ],
                        },
                        approvePage,
                    ],
                    selectedChoiceIndex: null,
                    dirty: false,
                }
            ],
        }
    };
}

function checkCprPage<RejectPage extends FormItem, ApprovePage extends FormItem>(task: GardenerInformationReviewTaskDocument, personalInformation: PersonalInformationDocument, rejectPage: RejectPage, approvePage: ApprovePage): CheckCprPage<RejectPage, ApprovePage> {
    const shouldCheckCpr = personalInformation.cpr && personalInformation.cpr !== task.data.cpr;
    if ( !shouldCheckCpr ) return approvePage;

    if ( !personalInformation.cpr ) throw new Error("Expected old cpr");
    if ( !task.data.cpr ) throw new Error("Expected new cpr");
    if ( !task.reasonForUpdatingCpr ) throw new Error("Expected reason for updating cpr");

    const oldCprType = (() => {
        const oldCpr = danishSsn(personalInformation.cpr);

        if ( !oldCpr.valid ) return "ugyldigt";
        if ( oldCpr.sex === "Female" ) return "kvinde";
        if ( oldCpr.sex === "Male" ) return "mand";

        throw new Error("Unexpected type for old cpr");
    })();

    const newCprType = (() => {
        const newCpr = danishSsn(task.data.cpr);

        if ( !newCpr.valid ) throw new Error("New cpr is invalid");
        if ( newCpr.sex === "Female" ) return "kvinde";
        if ( newCpr.sex === "Male" ) return "mand";

        throw new Error("Unexpected type for new cpr");
    })();

    return {
        type: "page",
        title: "Tjek CPR-nummer",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: "Du skal tjekke om havemandens ændring opfylder vores regler.",
                },
                {
                    type: "notice",
                    render: "info",
                    html: `
                        <strong>Havemandens tidligere CPR-nummer</strong><br />
                        ${personalInformation.cpr} (${oldCprType})<br />
                        <br />
                        <strong>Havemandens nye CPR-nummer</strong><br />
                        ${task.data.cpr} (${newCprType})<br />
                        <br />
                        <strong>Årsag til ændring af CPR-nummer</strong><br />
                        ${task.reasonForUpdatingCpr}
                    `,
                },
                {
                    type: "notice",
                    render: "success",
                    html: `
                        <strong>Tilladte ændringer</strong><br />
                        <br />
                        <ul>
                            <li>Hvis CPR-nummeret var forkert angivet før, må havemanden gerne rette det</li>
                            <li>Hvis havemanden har skiftet juridisk CPR-nummer, må havemanden gerne rette det</li>
                        </ul>
                    `,
                },
                {
                    type: "notice",
                    render: "danger",
                    html: `
                        <strong>Skal overholdes</strong><br />
                        <br />
                        <ul>
                            <li>Kønnet må kun ændres ved fejl i det gamle CPR-nummer eller hvis der er foretaget juridisk kønsskifte</li>
                            <li>Profilen må ikke overdrages til en anden person, dvs.at CPR-nummeret ikke må ændres uden en gyldig årsag</li>
                        </ul>
                    `,
                },
                {
                    type: "notice",
                    render: "warning",
                    html: `
                        <strong>OBS:</strong> Kan du ikke afgøre ud fra havemandens årsag, om det er en gyldig rettelse, så skal du afvise den.
                    `,
                },
                {
                    id: ManualReviewResponseFormIds.SingleChoiceCheckCpr,
                    type: "single-choice",
                    mode: "regular",
                    render: "horizontal-buttons",
                    questionText: "Vil du godkende havemandens ændring?",
                    answerTexts: ["Nej", "Ja"],
                    answerItems: [
                        {
                            type: "group",
                            render: "all at once",
                            items: [
                                {
                                    id: ManualReviewResponseFormIds.TextAreaRejectCprReason,
                                    type: "text-area",
                                    label: "Årsag til afvisning af stamdata (sendes til havemanden)",
                                    placeholder: "Fortæl havemanden hvorfor ændringen ikke kan godkendes",
                                    value: "",
                                    dirty: false,
                                    validation: "mandatory",
                                },
                                rejectPage,
                            ],
                        },
                        approvePage,
                    ],
                    selectedChoiceIndex: null,
                    dirty: false,
                }
            ],
        }
    };
}

function checkParticipantPage<RejectPage extends FormItem, ApprovePage extends FormItem>(task: GardenerInformationReviewTaskDocument, gardener: GardenerDocument, company: IndividualSellerCompany | null, rejectPage: RejectPage, approvePage: ApprovePage): CheckParticipantPage<RejectPage, ApprovePage> {    
    const shouldCheckCompany = task.data.cvr && gardener.cvr !== task.data.cvr;
    if ( !shouldCheckCompany ) return approvePage;

    if ( !company ) throw new Error("Expected new company");

    return checkParticipantPageImpl(task.data.name, company, rejectPage, approvePage, {
        SingleChoiceCanSignForCompany: ManualReviewResponseFormIds.SingleChoiceCheckParticipant,
    }, "check data");
}

function approveChangePage(): ConfirmationPage {
    return {
        type: "page",
        title: "Godkend ændring af stamdata",
        item: {
            type: "info",
            render: "default",
            text: `
                Havemandens ændring træder i kraft med det samme.
            `,
        },
    };
}

function rejectChangePage(): ConfirmationPage {
    return {
        type: "page",
        title: "Afvis ændring af stamdata",
        item: {
            type: "info",
            render: "default",
            text: `
                Havemandens ændringer bliver afvist.<br />
                <br />
                Havemanden informeres om at ændringen er afvist og hvorfor.
            `,
        },
    };
}
