import moment from "moment";

import { GardenerInformationDueDiligenceTaskDocument } from "../../task/task.model";
import { skipAccordingToHoursAndMinutesSkippingWeekendAsWell } from "../../date-utility";

export const formatActionDate = (task: GardenerInformationDueDiligenceTaskDocument): string => {
    const date = skipAccordingToHoursAndMinutesSkippingWeekendAsWell(task.performAfterDate, { hour: 8, minute: 0 }, { hour: 8, minute: 0 });

    return moment(date).format("D MMMM YYYY HH:mm");
}

export const formatAction = (task: GardenerInformationDueDiligenceTaskDocument): string => {
    switch ( task.action ) {
        case "request": return "send anmodning";
        case "reminder 1": return "påmindelse 1";
        case "reminder 2": return "påmindelse 2";
        case "sanction": return "indfør sanktion";
    }
}
