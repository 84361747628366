import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, HorizontalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";
import { IndividualSellerCompany, SupportedCompany, SupportedEntitySellerCompany } from "../../company/company.model";

export interface CheckCompanySigningFormIds {
    SingleChoiceCanSignForCompany: string;
}

export type CheckCompanySigningForm<NextPage extends FormItem> = CheckParticipantPage<ConfirmationPage, NextPage> | CheckSigningRulePage<ConfirmationPage, NextPage> | NextPage;

export type CheckParticipantPage<InvalidNamePage extends FormItem, ValidNamePage extends FormItem> = PageFormItem<
    GroupFormItem<[
        InfoFormItem,
        NoticeFormItem,
        NoticeFormItem,
        NoticeFormItem,
        HorizontalButtonsSingleChoiceFormItem<[
            InvalidNamePage,
            ValidNamePage,
        ]>
    ]>
>;

export type CheckSigningRulePage<InvalidNamePage extends FormItem, ValidNamePage extends FormItem> = PageFormItem<
    GroupFormItem<[
        InfoFormItem,
        NoticeFormItem,
        NoticeFormItem,
        NoticeFormItem,
        HorizontalButtonsSingleChoiceFormItem<[
            InvalidNamePage,
            ValidNamePage,
        ]>
    ]>
>;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCheckCompanySigningForm = <T extends FormItem>(gardener: GardenerDocument, company: SupportedCompany | null, nextPage: T, formIds: CheckCompanySigningFormIds): CheckCompanySigningForm<T> => {
    return useMemo(() => {
        if ( !company ) return nextPage;

        const rejectPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist fordi ansøgeren ikke kan tegne på vegne af virksomheden.
                `,
            },
        };

        switch ( company.type ) {
            case "personligt ejet mindre virksomhed": return checkParticipantPage(gardener.name, company, rejectPage, nextPage, formIds, "check application");
            case "enkeltmandsvirksomhed": return checkParticipantPage(gardener.name, company, rejectPage, nextPage, formIds, "check application");
            case "anpartsselskab": return checkSigningRulePage(gardener, company, rejectPage, nextPage, formIds);
            case "aktieselskab": return checkSigningRulePage(gardener, company, rejectPage, nextPage, formIds);
        }

    }, [gardener, company, nextPage, formIds]);
}

export function checkParticipantPage<RejectPage extends FormItem, ApprovePage extends FormItem>(gardenerName: string, company: IndividualSellerCompany, rejectPage: RejectPage, nextPage: ApprovePage, formIds: CheckCompanySigningFormIds, context: "check application" | "check data"): CheckParticipantPage<RejectPage, ApprovePage> {
    return {
        type: "page",
        title: "Tjek fuldt ansvarlig deltager",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: `
                        ${context === "check data" ? `Havemanden har angivet et CVR-nummer, som hører til en ${company.type}.` : `Ansøgeren har ansøgt som ${company.type}.`}<br />
                        <br />
                        Du skal tjekke at ${context === "check data" ? "havemanden" : "ansøgeren"} og virksomhedens fuldt ansvarlige deltager er samme person.
                    `,
                },
                {
                    type: "notice",
                    render: "info",
                    html: `
                        <strong>${context === "check data" ? "Havemandens" : "Ansøgers"} fulde navn</strong><br />
                        ${gardenerName}
                    `,
                },
                {
                    type: "notice",
                    render: "info",
                    html: `
                        <strong>Oplysninger fra CVR-registret</strong><br />
                        <br />
                        ${company.participants.map(participant => `
                            <strong>Fuldt ansvarlig deltager</strong><br />
                            ${participant.name}
                        `).join("<br /><br />")}
                    `,
                },
                {
                    type: "notice",
                    render: "warning",
                    html: `
                        <strong>OBS:</strong> Det skal være den samme person, men det er okay, hvis der ikke er et perfekt match ift. mellemnavne.
                    `,
                },
                {
                    id: formIds.SingleChoiceCanSignForCompany,
                    type: "single-choice",
                    mode: "switch page",
                    render: "horizontal-buttons",
                    questionText: `Er ${context === "check data" ? "havemanden" : "ansøgeren"} den fuldt ansvarlige deltager?`,
                    answerTexts: ["Nej", "Ja"],
                    answerItems: [
                        rejectPage,
                        nextPage,
                    ],
                    selectedChoiceIndex: null,
                    dirty: false,
                }
            ],
        },
    };
}

export const checkSigningRulePage = <RejectPage extends FormItem, ApprovePage extends FormItem>(gardener: GardenerDocument, company: SupportedEntitySellerCompany, rejectPage: RejectPage, approvePage: ApprovePage, formIds: CheckCompanySigningFormIds): CheckSigningRulePage<RejectPage, ApprovePage> => {
    return {
        type: "page",
        title: "Tjek tegningsregel",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: `
                        Ansøgeren har ansøgt som ${company.type}.<br />
                        <br />
                        Du skal tjekke at ansøgeren opfylder virksomhedens tegningsregel.
                    `,
                },
                {
                    type: "notice",
                    render: "info",
                    html: `
                        <strong>Ansøgers fulde navn</strong><br />
                        ${gardener.name}
                    `,
                },
                {
                    type: "notice",
                    render: "info",
                    html: `
                        <strong>Oplysninger fra CVR-registret</strong><br />
                        <br />
                        <strong>Tegningsregel</strong><br />
                        ${company.powerToBind}

                        ${company.management ? `
                            <br /><br />
                            <strong>Direktion</strong><br />
                            ${company.management.map(member => `${member.role}: ${member.name}`).join("<br />")}
                        ` : ""}

                        ${company.board ? `
                            <br /><br />
                            <strong>Bestyrelse</strong><br />
                            ${company.board.map(member => `${member.role}: ${member.name}`).join("<br />")}
                        ` : ""}
                    `,
                },
                {
                    type: "notice",
                    render: "warning",
                    html: `
                        <strong>OBS:</strong> Vi kræver at tegningsreglen kan opfyldes af ansøgeren alene, men vi accepterer, at der ikke er et perfekt match mellem ansøgerens fulde navn og en tegningsberettiget.<br />
                        <br />
                        Der findes mange forskellige tegningsregler, men her er nogle eksempler:<br />
                        <br />
                        <strong>Selskabet tegnes af en direktør</strong><br />
                        Det betyder at hver direktør kan opfylde tegningsreglen på egen hånd. Bestyrelsesmedlemmer kan ikke.<br />
                        <br />
                        <strong>Selskabet tegnes af to direktører</strong><br />
                        Det betyder, at ingen direktører kan opfylde tegningsreglen på egen hånd. Dette understøtter vi ikke.<br />
                        <br />
                        <strong>Selskabet tegnes af 1 direktør sammen med bestyrelsesformanden.</strong><br />
                        Det betyder, at ingen direktør og intet bestyrelsesmedlem kan opfylde tegningsreglen på egen hånd. Dette understøtter vi ikke. 
                    `,
                },
                {
                    id: formIds.SingleChoiceCanSignForCompany,
                    type: "single-choice",
                    mode: "switch page",
                    render: "horizontal-buttons",
                    questionText: "Kan ansøgeren opfylde tegningsreglen alene?",
                    answerTexts: ["Nej", "Ja"],
                    answerItems: [
                        rejectPage,
                        approvePage,
                    ],
                    selectedChoiceIndex: null,
                    dirty: false,
                }
            ],
        },
    };
}
