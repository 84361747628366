import { useAddGardenerComment } from "../../../comment/add-comment.hook";
import { useGardenerComments } from "../../../comment/comments.hook";
import { OnboardingTaskDocument } from "../../../task/task.model";
import { LinearProgress } from "../../../components/LinearProgress";
import { useAdministratorAction } from "../../../workflow/administrator-action.hook";
import { ApplicationDetailPage } from "./ApplicationDetailPage";
import { useGardener } from "../../../gardener/gardeners.hook";
import { ErrorPage } from "../../../ErrorPage";
import { useCompanyByCvr } from "../../../company/company.hook";

interface IntegratedApplicationDetailPageProps {
    task: OnboardingTaskDocument;
    goBack: () => void;
}

export const IntegratedApplicationDetailPage = (props: IntegratedApplicationDetailPageProps) => {
    const { task, goBack } = props;

    const gardener = useGardener(task.gardenerId);
    const company = useCompanyByCvr(gardener && gardener.cvr);
    const administratorAction = useAdministratorAction(goBack);

    const comments = useGardenerComments(task?.gardenerId);
    const addComment = useAddGardenerComment(task?.gardenerId);

    if ( gardener === null ) return <ErrorPage message="Havemanden kunne ikke findes" />;
    if ( gardener?.cvr && company === null ) return <ErrorPage message="Kunne ikke opslå havemandens virksomhed" />;
    if ( company?.data.type === "frivillig forening" ) return <ErrorPage message="Understøtter ikke ansøgninger fra en frivillig forening" />;
    if ( company?.data.type === "interessentskab" ) return <ErrorPage message="Understøtter ikke ansøgninger fra et interessentskab" />;
    if ( company?.data.type === "iværksætterselskab" ) return <ErrorPage message="Understøtter ikke ansøgninger fra et iværksætterselskab" />;
    if ( gardener === undefined || company === undefined || comments === undefined ) return <LinearProgress />;

    return (
        <ApplicationDetailPage
            gardener={gardener}
            company={company && company.data}
            task={task}
            administratorAction={administratorAction}
            comments={comments}
            addComment={addComment}
            goBack={goBack}
        />
    );
};
