import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { GardenerInformationSubmittedCommentDocument } from "../comment.model";

interface GardenerInformationSubmittedCommentProps {
    comment: GardenerInformationSubmittedCommentDocument;
}

export const GardenerInformationSubmittedComment = (props: GardenerInformationSubmittedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemanden har indsendt nye stamdata">
            <CommentBody>
                Havemanden har indsendt nye stamdata.
            </CommentBody>
        </Comment>
    );
};
