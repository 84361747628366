import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useTask } from "../../task/tasks.hook";
import { GardenerInformationDueDiligenceTaskDocument, GardenerInformationReviewTaskDocument } from "../../task/task.model";
import { NotFoundPage } from "../../NotFoundPage";
import { LinearProgress } from "../../components/LinearProgress";
import { ErrorPage } from "../../ErrorPage";
import { IntegratedManualReviewDetailPage } from "./manual-review/IntegratedManualReviewDetailPage";
import { IntegratedAutomaticDetailPage } from "./automatic/IntegratedAutomaticDetailPage";

export const IntegratedCheckGardenerInformationDetailPage = () => {
    const { tab, taskId } = useParams<{ tab: string, taskId: string }>();

    const history = useHistory();
    const goBack = useCallback(() => {
        history.push(`/check-gardener-information/${tab}`);
    }, [history, tab]);

    const task = useTask(taskId) as GardenerInformationReviewTaskDocument | GardenerInformationDueDiligenceTaskDocument;

    if ( task === null ) return <NotFoundPage />;
    if ( task === undefined ) return <LinearProgress />;

    if ( task.type === "gardener information review" ) return <IntegratedManualReviewDetailPage task={task} goBack={goBack} />;
    if ( task.type === "gardener information due diligence" ) return <IntegratedAutomaticDetailPage task={task} goBack={goBack} />;

    return <ErrorPage message="Opgavens type genkendes ikke" />;
};
