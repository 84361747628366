import { Link } from "react-router-dom";

import { Card } from "../../../components/card/Card";
import { CardHeader } from "../../../components/card/CardHeader";
import { CardContent } from "../../../components/card";
import { GardenerInformationDueDiligenceTaskDocument } from "../../../task/task.model";
import { formatAction, formatActionDate } from "../../shared/utility";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    content: {
        "& > a": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
    
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
});

interface AutomaticTaskDetailCardProps {
    task: GardenerInformationDueDiligenceTaskDocument;
}

export const AutomaticTaskDetailCard = (props: AutomaticTaskDetailCardProps) => {
    const classes = useStyles();
    const { task } = props;

    const date = formatActionDate(task);
    const action = formatAction(task);

    const cardContent = (() => {
        const gardener = <Link to={`/gardeners/context/${task.gardenerId}`}>{task.gardenerName}</Link>;

        switch ( task.action ) {
            case "request":
                return (
                    <CardContent className={classes.content}>
                        Vi skal bede {gardener} om at indsende sine stamdata igen.<br />
                        <br />
                        Anmodning sendes {date} på email.
                    </CardContent>
                );

            case "reminder 1":
                return (
                    <CardContent className={classes.content}>
                        {gardener} har endnu ikke indsendt sine stamdata.<br />
                        <br />
                        Første påmindelse sendes {date} på email.
                    </CardContent>
                );

            case "reminder 2":
                return (
                    <CardContent className={classes.content}>
                        {gardener} har endnu ikke indsendt sine stamdata.<br />
                        <br />
                        Anden påmindelse sendes {date} på email og sms.
                    </CardContent>
                );

            case "sanction":
                return (
                    <CardContent className={classes.content}>
                        {gardener} har ikke reageret på vores anmodning og to påmindelser om indsendelse af stamdata.<br />
                        <br />
                        Vi er forpligtede til at sanktionere havemanden jf. DAC7.<br />
                        <br />
                        Sanktion indføres {date} og havemanden informeres på email og sms.
                    </CardContent>
                );
        }
    })();

    return (
        <Card>
            <CardHeader>{task.gardenerName}, {action}</CardHeader>

            {cardContent}

        </Card>
    );
};
