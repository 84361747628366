import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { ComplaintCommentDocument } from "../comment/comment.model";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    link: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    },
});


interface ComplaintCommentProps {
    comment: ComplaintCommentDocument;
}

export const ComplaintComment = (props: ComplaintCommentProps) => {
    const classes = useStyles();
    const { comment } = props;

    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;

    const invoices = comment.data.invoices.map(invoice => (
        <Link key={invoice.id} to={`/invoices/all/invoice/${invoice.id}`} className={classes.link}>Faktura {invoice.invoiceNumber}</Link>
    ));

    const invoicesListed = (
        <>
            {invoices.map(((invoice, index) => (
                <span key={index}>
                    {invoice}{index < invoices.length - 2 ? ", " : index === invoices.length - 2 ? " og " : null}
                </span>
            )))}
        </>
    );

    const title = (() => {
        switch ( comment.data.event ) {
            case "complaint created": return "Reklamation oprettet";
            case "called customer once without contact": return "Vi fik ikke fat i kunden ift. reklamation";
            case "complaint handed over to gardener": return "Reklamation overdraget til havemand";
            case "talked to customer": return "Vi har talt med kunden ift. reklamation";
            case "called gardener once without contact": return "Vi fik ikke fat i havemand ift. reklamation";
            case "talked to gardener": return "Vi har talt med havemand ift. reklamation";
            case "offered proposal": return "Kunde tilbudt løsning ift. reklamation";
            case "complaint resolved": return "Reklamation afsluttet";
        }
    })();

    const message = (() => {
        switch ( comment.data.event ) {
            case "complaint created":
                return (
                    <>
                        {customer} har reklameret over {invoicesListed} leveret af {gardener}.
                    </>
                );

            case "called customer once without contact":
                return (
                    <>
                        {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                        <br />
                        Vi har ringet til kunden, men fik ikke fat i dem. Vi vil forsøge én gang til.
                    </>
                );

            case "complaint handed over to gardener":
                switch ( comment.data.reason ) {
                    case "customer could not be reached":
                        return (
                            <>
                                {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                                <br />
                                Vi har nu ringet til kunden to gange uden at få fat i dem. Reklamationen er derfor blevet overdraget til havemanden.
                            </>
                        );

                    case "customer did not have proposal":
                        return (
                            <>
                                {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                                <br />
                                Vi har talt med kunden ift. reklamationen, men de havde ikke et rimeligt løsningsforslag. Reklamationen er derfor blevet overdraget til havemanden.
                            </>
                        );

                    case "gardener could not be reached":
                        return (
                            <>
                                {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                                <br />
                                Vi har nu ringet til havemanden to gange uden at få fat i dem. Reklamationen er derfor blevet overdraget til havemanden.
                            </>
                        );

                    case "gardener did not have proposal": {
                        const explanation = comment.data.explanation ? (
                            <>
                                <br /><br />
                                Uddybende forklaring:<br />
                                {comment.data.explanation}
                            </>
                        ) : null;

                        return (
                            <>
                                {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                                <br />
                                Efter at have talt med kunden og havemanden, kunne vi ikke tilbyde kunden en løsning. Reklamationen er derfor blevet overdraget til havemanden.
                                {explanation}
                            </>
                        );
                    }

                    case "customer did not accept proposal": {
                        const explanation = comment.data.explanation ? (
                            <>
                                <br /><br />
                                Uddybende forklaring:<br />
                                {comment.data.explanation}
                            </>
                        ) : null;

                        return (
                            <>
                                {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                                <br />
                                Kunden blev tilbudt en løsning, men accepterede den ikke. Reklamationen er derfor blevet overdraget til havemanden.
                                {explanation}
                            </>
                        );
                    }
                }
                break;

            case "talked to customer":
                return (
                    <>
                        {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                        <br />
                        Vi har talt med kunden ift. reklamationen. Vi skal nu kontakte havemanden for at hjælpe dem med at finde en løsning.
                    </>
                );

            case "called gardener once without contact":
                return (
                    <>
                        {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                        <br />
                        Vi har ringet til havemanden, men vi fik ikke fat i dem. Vi vil forsøge én gang til.
                    </>
                );

            case "talked to gardener":
                return (
                    <>
                        {customer} har reklameret over {invoicesListed} leveret af {gardener}.<br />
                        <br />
                        Vi har talt med kunden og havemanden ift. reklamationen. Direktionen skal nu afgøre, om kunden kan tilbydes en løsning.
                    </>
                );

            case "offered proposal":
                return (
                    <>
                        {customer} er blevet tilbudt en løsning på sin reklamation af {invoicesListed} leveret af {gardener}.<br />
                        <br />
                        Kunden har nu 7 dage til at acceptere løsningen, ellers vil reklamationen blive overdraget til havemanden.
                    </>
                );

            case "complaint resolved":
                return (
                    <>
                        {customer} reklamerer ikke længere over {invoicesListed} leveret af {gardener}.<br />
                        <br />
                        Årsag:<br />
                        “{comment.data.reason.text}”
                    </>
                );
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title={title} author={comment.administratorName}>
            <CommentBody>
                {message}
            </CommentBody>
        </Comment>
    );
};
