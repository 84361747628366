import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { GardenerInformationApprovedCommentDocument } from "../comment.model";

interface GardenerInformationApprovedCommentProps {
    comment: GardenerInformationApprovedCommentDocument;
}

export const GardenerInformationApprovedComment = (props: GardenerInformationApprovedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Stamdata godkendt" author={comment.administratorName ?? undefined}>
            <CommentBody>
                Havemandens nye stamdata blev godkendt ved {comment.administratorName ? "manuel gennemgang" : "automatisk gennemgang"}.
            </CommentBody>
        </Comment>
    );
};
