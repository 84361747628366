import { useCallback, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { TaskDocument } from "../../task/task.model";
import { AutomaticTab } from "./AutomaticTab";
import { useOpenTasksByType } from "../../task/tasks.hook";
import { LinearProgress } from "../../components/LinearProgress";

export const IntegratedAutomaticTab = () => {
    const history = useHistory();
    const goToTask = useCallback((task: TaskDocument) => {
        history.push(`/check-gardener-information/automatic/${task.id}`);
    }, [history]);

    const now = useRef(moment().toISOString(true)).current;
    const tasks = useOpenTasksByType("gardener information due diligence");
    const relevantTasks = useMemo(() => {
        if ( tasks === undefined ) return undefined;

        return tasks.filter(task => task.performAfterDate > now);
    }, [tasks, now]);

    if ( relevantTasks === undefined ) return <LinearProgress />;

    return (
        <AutomaticTab
            tasks={relevantTasks}
            goToTask={goToTask}
        />
    );
};
