import { Container } from "@material-ui/core";

import { GardenerInformationDueDiligenceTaskDocument } from "../../../task/task.model";
import { CommentDocument } from "../../../comment/comment.model";
import { Message } from "../../../Message";
import { ActivitySection } from "../../../comment/ActivitySection";
import { BackButton } from "../../../components/BackButton";
import { Section } from "../../../components/section";
import { TitleHeader } from "../../../components/TitleHeader";
import { ContentSection } from "../../../layout/ContentSection";
import { GardenerCommentList } from "../../../comment/GardenerCommentList";
import { AutomaticTaskDetailCard } from "./AutomaticTaskDetailCard";

export interface AutomaticDetailPageProps {
    task: GardenerInformationDueDiligenceTaskDocument;
    comments: CommentDocument[];
    addComment: (comment: string) => void;
    goBack: () => void;
}

export const AutomaticDetailPage = (props: AutomaticDetailPageProps) => {
    const { task, comments, addComment } = props;

    const backButton = <BackButton onClick={props.goBack} />;

    return (
        <>
            <TitleHeader button={backButton}>Automatisk håndtering</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>

                        {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

                        <AutomaticTaskDetailCard task={task} />

                    </Section>

                    <ActivitySection onSave={addComment}>
                        <GardenerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
