import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { GardenerInformationReviewNeededCommentDocument } from "../comment.model";

interface GardenerInformationReviewNeededCommentProps {
    comment: GardenerInformationReviewNeededCommentDocument;
}

export const GardenerInformationReviewNeededComment = (props: GardenerInformationReviewNeededCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Behov for manuel gennemgang af nye stamdata">
            <CommentBody>
                Havemandens nye stamdata blev sendt til manuel gennemgang.
            </CommentBody>
        </Comment>
    );
};
