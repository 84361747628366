import { useMemo } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import { TitleHeader } from "../../components/TitleHeader";
import { TabBar } from "../../components/tabs/TabBar";
import { useGardenerInformationReviewTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { IntegratedManualReviewTab } from "./IntegratedManualReviewTab";
import { IntegratedAutomaticTab } from "./IntegratedAutomaticTab";

export const IntegratedCheckGardenerInformationOverviewPage = () => {
    const { tab } = useParams<{ tab: "manual-review" | "automatic" }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "manual-review": return 0;
            case "automatic": return 1;

            default: return 0;
        }
    }, [tab]);

    const gardenerInformationReviewTasks = useGardenerInformationReviewTasks();

    if ( tab !== "manual-review" && tab !== "automatic" ) return <Redirect to="/check-gardener-information/manual-review" />;

    return (
        <>
            <TitleHeader>Tjek stamdata</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/check-gardener-information/manual-review">{formatTitle("Til gennemgang", gardenerInformationReviewTasks.length)}</TabBarItem>
                <TabBarItem href="/check-gardener-information/automatic">Automatisk håndtering</TabBarItem>
            </TabBar>

            <Switch>

                <Route exact path="/check-gardener-information/manual-review">
                    <IntegratedManualReviewTab />
                </Route>

                <Route exact path="/check-gardener-information/automatic">
                    <IntegratedAutomaticTab />
                </Route>

            </Switch>
        </>
    );
};

function formatTitle(text: string, count: number): string {
    if ( count === 0 ) return text;

    return `${text} (${count})`;
};
