import { useAddGardenerComment } from "../../../comment/add-comment.hook";
import { useGardenerComments } from "../../../comment/comments.hook";
import { GardenerInformationReviewTaskDocument } from "../../../task/task.model";
import { LinearProgress } from "../../../components/LinearProgress";
import { ManualReviewDetailPage } from "./ManualReviewDetailPage";
import { useGardener } from "../../../gardener/gardeners.hook";
import { useCompanyByCvr } from "../../../company/company.hook";
import { ErrorPage } from "../../../ErrorPage";
import { usePersonalInformation } from "../../../gardener/personal-information.hook";
import { useAdministratorAction } from "../../../workflow/administrator-action.hook";

interface IntegratedManualReviewDetailPageProps {
    task: GardenerInformationReviewTaskDocument;
    goBack: () => void;
}

export const IntegratedManualReviewDetailPage = (props: IntegratedManualReviewDetailPageProps) => {
    const { task, goBack } = props;

    const gardener = useGardener(task.gardenerId);
    const company = useCompanyByCvr(task.data.cvr);
    const personalInformation = usePersonalInformation(task.gardenerId);

    const administratorAction = useAdministratorAction(goBack);

    const comments = useGardenerComments(task?.gardenerId);
    const addComment = useAddGardenerComment(task?.gardenerId);

    if ( gardener === null ) return <ErrorPage message="Havemanden kunne ikke findes" />;
    if ( task.data.cvr && company === null ) return <ErrorPage message="Kunne ikke opslå havemandens virksomhed" />;
    if ( company?.data.type === "frivillig forening" ) return <ErrorPage message="Understøtter ikke frivillig foreninger" />;
    if ( company?.data.type === "interessentskab" ) return <ErrorPage message="Understøtter ikke interessentskaber" />;
    if ( company?.data.type === "iværksætterselskab" ) return <ErrorPage message="Understøtter ikke iværksætterselskaber" />;
    if ( company?.data.type === "anpartsselskab" ) return <ErrorPage message="Understøtter ikke anpartsselskaber" />;
    if ( company?.data.type === "aktieselskab" ) return <ErrorPage message="Understøtter ikke aktieselskaber" />;
    if ( personalInformation === null ) return <ErrorPage message="Kunne ikke opslå havemandens personlige information" />;
    if ( gardener === undefined || company === undefined || personalInformation === undefined || comments === undefined ) return <LinearProgress />;

    return (
        <ManualReviewDetailPage
            task={task}
            gardener={gardener}
            company={company && company.data}
            personalInformation={personalInformation}
            administratorAction={administratorAction}
            comments={comments}
            addComment={addComment}
            goBack={goBack}
        />
    );
};
